import { DriverWorkflow } from '@symfa-inc/providence-verizon-types';
import { DetailsResolver } from '@core/resolvers';
import { HttpService } from '@core/services';
import {
  DriverManagementHttpService,
  ProjectHttpService,
  ProjectManagementHttpService,
} from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';

export class ProjectManagementDetailsResolver extends DetailsResolver {
  async resolve(): Promise<void> {
    await super.resolve();

    const [projectBundleID] = getProjectBundleIDParamFromUrl();

    await HttpService.getHttpRequests(ProjectHttpService).getProject(
      projectBundleID,
    );

    await HttpService.getHttpRequests(
      ProjectManagementHttpService,
    ).getProjectManagementDetails(projectBundleID);

    await HttpService.getHttpRequests(
      DriverManagementHttpService,
    ).getDriversListByWorkflow(DriverWorkflow.ProjectManagement);
  }
}
