import { FRONTEND_DATE_FORMAT } from '@symfa-inc/providence-verizon-types';
import { DateWorkerNull } from '@models/types';
import { DateWorker, dateWorker } from '../utils/date-worker';

export class ObjectComparatorService {
  static objectsCompare<T extends Record<string, any>>(
    obj1: T,
    obj2: T,
    nullAndUndefinedAndEmptyStringNotStrict?: boolean,
  ): boolean {
    const keys = Object.keys(obj1);
    const emptyValues = [null, undefined, ''];

    return keys.every((key: string) => {
      switch (true) {
        case dateWorker.isMomento(obj1[key]):
          return this.dateCompare(obj1[key], obj2[key]);
        case Array.isArray(obj1[key]):
          return this.arraysCompare(obj1[key], obj2[key]);
        case this.isObjects(obj1[key], obj2[key]):
          return this.objectsCompare(obj1[key], obj2[key]);
        case nullAndUndefinedAndEmptyStringNotStrict &&
          emptyValues.includes(obj1[key]) &&
          emptyValues.includes(obj2[key]):
          return true;
        default:
          return obj1[key] === obj2[key];
      }
    });
  }

  static arraysCompare<T>(
    arr1: T[],
    arr2: T[],
    nullAndUndefinedAndEmptyStringNotStrict?: boolean,
  ): boolean {
    const arr1Sorted = arr1 && [...arr1].sort();
    const arr2Sorted = arr2 && [...arr2].sort();

    return (
      !!arr1Sorted &&
      !!arr2Sorted &&
      arr1Sorted.length === arr2Sorted.length &&
      arr1Sorted.every((element: T, index: number): boolean =>
        this.isObjects(element, arr2Sorted[index])
          ? this.objectsCompare(
              element as Record<string, any>,
              arr2Sorted[index] as Record<string, any>,
              nullAndUndefinedAndEmptyStringNotStrict,
            )
          : element === arr2Sorted[index],
      )
    );
  }

  static isObjects<T>(element1: T, element2: T): boolean {
    return (
      typeof element1 === 'object' &&
      element1 !== null &&
      typeof element2 === 'object' &&
      element2 !== null
    );
  }

  static dateCompare(date1: DateWorker, date2: DateWorkerNull): boolean {
    if (!date2) {
      return false;
    }

    return (
      dateWorker(date1).format(FRONTEND_DATE_FORMAT) ===
      dateWorker(date2).format(FRONTEND_DATE_FORMAT)
    );
  }
}
