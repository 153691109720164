import { RemovePermitTypeData } from '@models/types';
import { actionCreatorFactory } from '../../core/utils/factories';
import {
  AddPermitTypeData,
  JurisdictionBrowse,
  JurisdictionDetails,
  JurisdictionDetailsContacts,
  JurisdictionDetailsPermitting,
  JurisdictionDetailsZoning,
  JurisdictionModalValues,
  JurisdictionOption,
  JurisdictionPermittingData,
  JurisdictionZoningData,
  PaginatedResponse,
  PermittingBrowseResponse,
  PermittingDetails,
  PermittingJurisdictionAuditBody,
  PermittingWorkflowBody,
} from '../../models/interfaces';

const actionCreator = actionCreatorFactory('PERMITTING');

export namespace PermittingActions {
  // PERMITTING
  export const getPermittingBrowseData = actionCreator.createAsyncAction<
    PaginatedResponse<PermittingBrowseResponse>
  >('GET_PERMITTING_BROWSE_DATA');

  export const getJurisdictionList = actionCreator.createAsyncAction<
    JurisdictionOption[]
  >('GET_JURISDICTION_LIST');

  export const getPermittingDetailsData =
    actionCreator.createAsyncAction<PermittingDetails>(
      'GET_PERMITTING_DETAILS_DATA',
    );

  export const setPermittingTypesAction = actionCreator.createAsyncAction<
    string[]
  >('SET_PERMITTING_TYPES');

  export const resetPermittingTypesAction = actionCreator.createAsyncAction<
    string[]
  >('RESET_PERMITTING_TYPES');

  export const permittingAddPermittingType =
    actionCreator.createAsyncAction<AddPermitTypeData>('ADD_PERMITTING_TYPE');

  export const permittingUpdatePermittingType =
    actionCreator.createAsyncAction<AddPermitTypeData>(
      'UPDATE_PERMITTING_TYPE',
    );

  export const permittingRemovePermittingType =
    actionCreator.createAsyncAction<RemovePermitTypeData>(
      'REMOVE_PERMITTING_TYPE',
    );

  export const permittingUpdateWorkflowData =
    actionCreator.createAsyncAction<PermittingWorkflowBody>(
      'UPDATE_WORKFLOW_DATA',
    );

  export const permittingUpdateJurisdictionAuditData =
    actionCreator.createAsyncAction<PermittingJurisdictionAuditBody>(
      'UPDATE_JURISDICTION_AUDIT_DATA',
    );
  // JURISDICTION
  export const getJurisdictionBrowseData = actionCreator.createAsyncAction<
    PaginatedResponse<JurisdictionBrowse>
  >('GET_JURISDICTION_BROWSE_DATA');

  export const addJurisdiction =
    actionCreator.createAsyncAction<PaginatedResponse<JurisdictionBrowse>>(
      'ADD_JURISDICTION',
    );

  export const getJurisdictionDetailsData =
    actionCreator.createAsyncAction<JurisdictionDetails>(
      'GET_JURISDICTION_DETAILS_DATA',
    );
  // JURISDICTION - ZONING
  export const addJurisdictionZoningPanel =
    actionCreator.createAsyncAction<JurisdictionDetailsZoning>(
      'ADD_JURISDICTION_ZONING_PANEL',
    );

  export const updateJurisdictionZoning =
    actionCreator.createAsyncAction<JurisdictionZoningData>(
      'UPDATE_JURISDICTION_ZONING',
    );

  export const updateJurisdictionZoningPanel =
    actionCreator.createAsyncAction<JurisdictionModalValues>(
      'UPDATE_JURISDICTION_ZONING_PANEL',
    );

  export const removeJurisdictionZoningPanel =
    actionCreator.createAsyncAction<string>('REMOVE_JURISDICTION_ZONING_PANEL');
  // JURISDICTION - PERMITTING

  export const addJurisdictionPermittingPanel =
    actionCreator.createAsyncAction<JurisdictionDetailsPermitting>(
      'ADD_JURISDICTION_PERMITTING_PANEL',
    );

  export const updateJurisdictionPermittingPanel =
    actionCreator.createAsyncAction<JurisdictionModalValues>(
      'UPDATE_JURISDICTION_PERMITTING_PANEL',
    );

  export const removeJurisdictionPermittingPanel =
    actionCreator.createAsyncAction<string>(
      'REMOVE_JURISDICTION_PERMITTING_PANEL',
    );

  export const updateJurisdictionPermitting =
    actionCreator.createAsyncAction<JurisdictionPermittingData>(
      'UPDATE_JURISDICTION_PERMITTING',
    );
  // JURISDICTION - CONTACTS
  export const addJurisdictionContact =
    actionCreator.createAsyncAction<JurisdictionDetailsContacts>(
      'ADD_JURISDICTION_CONTACT',
    );

  export const updateJurisdictionContact =
    actionCreator.createAsyncAction<JurisdictionDetailsContacts>(
      'UPDATE_JURISDICTION_CONTACT',
    );

  export const removeJurisdictionContact =
    actionCreator.createAsyncAction<string>('REMOVE_JURISDICTION_CONTACT');
}
