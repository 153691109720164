import {
  ProjectStatus,
  Undefinable,
  UserRole,
} from '@symfa-inc/providence-verizon-types';

export const canUserEditByProjectStatusAndRoles = (
  projectStatus: Undefinable<ProjectStatus>,
  roles?: UserRole[],
) =>
  (projectStatus && projectStatus !== ProjectStatus.Hold) ||
  roles?.includes(UserRole.Accounting) ||
  roles?.includes(UserRole.Admin) ||
  roles?.includes(UserRole.Permitting) ||
  roles?.includes(UserRole.EngineeringManager);
