import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropDownSelectorValues } from '@symfa-inc/providence-verizon-types';
import { useForm } from 'antd/lib/form/Form';
import { getFormData, isValidForm } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { JurisdictionDetailsPermittingType } from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import { Autocomplete, Input, ShowMoreInput } from '@shared/modules';
import { PermittingActions } from '@store/actions';
import { DDVSelectors } from '@store/selectors';
import { PermitTypeTableModalProps } from '../models';

export const PermitTypeTableModal: FC<PermitTypeTableModalProps> = ({
  visibleModal,
  toggleModal,
  formValue,
  permittingId,
  modalType,
}: PropsWithChildren<PermitTypeTableModalProps>) => {
  const [form] = useForm();

  const dispatch = useDispatch();

  const permitTypeDDV = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByType(DropDownSelectorValues.PermitType),
  );

  useEffect(() => {
    if (visibleModal) {
      const { requiredInfoForObtainingPermit, ...data } = formValue;

      form.setFieldsValue({
        ...data,
        requiredInfoForObtainingPermit: requiredInfoForObtainingPermit || '',
      });
    }
  }, [visibleModal, formValue, form]);

  const addPermittingType = async (
    data: JurisdictionDetailsPermittingType,
  ): Promise<void> => {
    if (await isValidForm(form)) {
      dispatch(
        PermittingActions.permittingAddPermittingType.done({
          permittingId,
          data,
        }),
      );

      toggleModal();
    }
  };

  const editPermitType = async (
    data: JurisdictionDetailsPermittingType,
  ): Promise<void> => {
    if (await isValidForm(form)) {
      dispatch(
        PermittingActions.permittingUpdatePermittingType.done({
          permittingId,
          data,
        }),
      );

      toggleModal();
    }
  };

  return (
    <AddOrEditModal
      className="permit-type-table__modal"
      visible={visibleModal}
      type={modalType}
      cancelText="Cancel"
      onOk={
        modalType === ModalMainTypes.Edit
          ? (): Promise<void> => editPermitType(getFormData(formValue, form))
          : (): Promise<void> => addPermittingType(getFormData(formValue, form))
      }
      onCancel={toggleModal}
      title={
        modalType === ModalMainTypes.Edit ? 'Permit Type' : 'New Permit Type'
      }
      formProps={{
        labelCol: { span: 12 },
        wrapperCol: { span: 14 },
        form,
      }}
    >
      <Autocomplete
        id="permitType"
        label="Permit Type"
        options={permitTypeDDV}
        formItemProps={{
          rules: [
            {
              required: true,
              message: 'Permit Type is required!',
            },
          ],
        }}
      />
      <Input id="expectedPermitLeadTime" label="Expected Permit Lead Time" />
      <Input id="permitAndApplicationFee" label="Permit and Application Fees" />
      <ShowMoreInput
        id="requiredInfoForObtainingPermit"
        label="Required Info for Obtaining Permit"
        data={formValue.requiredInfoForObtainingPermit}
        form={form}
      />
    </AddOrEditModal>
  );
};
