import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DropDownSelectorValues,
  MAIN_PACE_TASK_IDs,
  PaceTaskType,
  ProjectStatus as ProjectStatusEnum,
  SubPage,
  Tab,
} from '@symfa-inc/providence-verizon-types';
import { Form } from 'antd';
import classNames from 'classnames';
import { Store } from 'rc-field-form/lib/interface';
import { HttpService, ObjectComparatorService } from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import {
  canUserEditByProjectStatusAndRoles,
  isValidForm,
  momentizeObjectDates,
} from '@core/utils/methods';
import { JURISDICTION_DATES_FOR_MOMENTIZE } from '@models/constants';
import {
  JurisdictionDetailsPermittingType,
  PermittingDetailsJurisdictionPermitting,
} from '@models/interfaces';
import { EditablePage, NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions, PermittingActions } from '@store/actions';
import { ProjectsEffects } from '@store/effects';
import {
  DDVSelectors,
  PaceTasksSelectors,
  PermittingSelectors,
  ProjectFuzeIDsSelectors,
  ProjectSelectors,
  UserSelectors,
} from '@store/selectors';
import {
  JurisdictionAuditPermittingSection,
  JurisdictionAuditSummarySection,
  JurisdictionAuditZoningSection,
  NoJurisdiction,
} from './components';
import { dataToBodyMapper } from './helpers';
import { JurisdictionAuditProps } from './models';

import './styles.scss';

const { useForm } = Form;

const JurisdictionAudit: FC<JurisdictionAuditProps> = ({
  isEditing,
  permissions,
  data: jurisdictionData,
  toggleEditing,
}: PropsWithChildren<JurisdictionAuditProps>) => {
  const dispatch = useDispatch();

  const [summaryForm] = useForm();
  const [permittingForm] = useForm();
  const [zoningForm] = useForm();

  const [workflowForm, setWorkflowForm] = useState<Array<Store>>([]);
  const [initialForm, setInitialForm] = useState<Array<Store>>([]);

  const { currentPermitTypeTableData, originalPermitTypeTableData } =
    useSelector(
      PermittingSelectors.getPermitTypeTableData(
        jurisdictionData?.permitting?.id,
      ),
    );

  const arraysCompare =
    ObjectComparatorService.arraysCompare(initialForm, workflowForm, true) &&
    ObjectComparatorService.arraysCompare(
      originalPermitTypeTableData,
      currentPermitTypeTableData,
      true,
    );

  const projectFuzeIDsTableVisible = useSelector(
    ProjectFuzeIDsSelectors.getProjectFuzeIDsTableVisible,
  );
  const { permittingUsers } = useSelector(
    PermittingSelectors.getPermittingDetailsData(['permittingUsers']),
  );
  const { jurisdictionAuditComplete } = useSelector(
    PaceTasksSelectors.getMainPaceTaskDates(
      PaceTaskType.ActualDate,
      'jurisdictionAuditComplete',
    ),
  );
  const projectId = useSelector(PermittingSelectors.getProjectId);

  const [duringReqDisabled, setDuringReqDisabled] = useState<boolean>(false);

  const buttonsDisabled = duringReqDisabled || arraysCompare;

  const { zoning, contacts, ...summaryDefaultData } = useMemo(
    () => jurisdictionData!,
    [jurisdictionData],
  );

  const { permittingTypes, ...permittingDefaultData } =
    (jurisdictionData?.permitting ??
      {}) as PermittingDetailsJurisdictionPermitting;

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!arraysCompare));
  }, [arraysCompare]);

  const onSubmit = async (): Promise<void> => {
    if (
      (await isValidForm(summaryForm)) &&
      (await isValidForm(zoningForm)) &&
      (await isValidForm(permittingForm))
    )
      try {
        setDuringReqDisabled(true);

        const permittingJurisdictionAuditBody = dataToBodyMapper(
          initialForm,
          workflowForm,
          jurisdictionData!.id,
          currentPermitTypeTableData.map(
            ({
              generatedId,
              ...permitTypeTableItem
            }: JurisdictionDetailsPermittingType) => permitTypeTableItem,
          ),
          jurisdictionData!.zoning?.id,
          jurisdictionData!.permitting?.id,
        );

        await HttpService.getHttpRequests(
          PermittingHttpService,
        ).updatePermittingJurisdictionAuditData(
          projectId,
          permittingJurisdictionAuditBody,
        );

        ProjectsEffects.updatePaceTaskFieldsByConfig(workflowForm[0], [
          {
            id: MAIN_PACE_TASK_IDs.jurisdictionAuditComplete,
            dateType: PaceTaskType.ActualDate,
            field: 'jurisdictionAuditComplete',
          },
        ]);

        toggleEditing?.();

        setDuringReqDisabled(false);

        setInitialForm(workflowForm);

        if (jurisdictionData?.permitting?.id) {
          dispatch(
            PermittingActions.setPermittingTypesAction.done([
              jurisdictionData.permitting.id,
            ]),
          );
        }

        if (isEditing) {
          toggleEditing?.();
        }

        NotificationsLoader.notificationSuccess(
          `Information has been updated!`,
        );
      } catch (e) {
        console.error(e);
      }
  };

  const onCancel = (): void => {
    const [summaryData, zoningData, permittingData] = initialForm;

    summaryForm?.setFieldsValue(
      momentizeObjectDates(summaryData || [], ['jurisdictionAuditComplete']),
    );
    zoningForm?.setFieldsValue(
      momentizeObjectDates(zoningData || [], JURISDICTION_DATES_FOR_MOMENTIZE),
    );
    permittingForm?.setFieldsValue(
      momentizeObjectDates(
        permittingData || [],
        JURISDICTION_DATES_FOR_MOMENTIZE,
      ),
    );

    setWorkflowForm(initialForm);

    if (jurisdictionData?.permitting?.id) {
      dispatch(
        PermittingActions.resetPermittingTypesAction.done([
          jurisdictionData.permitting.id,
        ]),
      );
    }

    if (isEditing) {
      toggleEditing?.();
    }
  };

  const onValuesChange = (): void => {
    if (Object.keys(summaryForm.getFieldsValue()).length) {
      const formsData = [
        summaryForm.getFieldsValue(),
        zoningForm.getFieldsValue(),
        permittingForm.getFieldsValue(),
      ];

      setWorkflowForm(formsData);

      if (!Object.keys(initialForm).length) {
        setInitialForm(formsData);
      }
    }
  };

  useDidUpdateEffect(() => {
    onValuesChange();
  }, [isEditing]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <main className="prov-permitting-jurisdiction-audit">
      <div
        className={classNames('prov-permitting-jurisdiction-audit__tab', {
          'tab_with-actions': isEditing,
          'fuze-ids-table-open': projectFuzeIDsTableVisible,
        })}
      >
        <JurisdictionAuditSummarySection
          jurisdictionAuditSummaryFields={
            permissions!.jurisdictionAuditSummaryFields
          }
          isEditing={isEditing}
          onValuesChange={onValuesChange}
          data={{
            ...summaryDefaultData,
            jurisdictionAuditComplete: jurisdictionAuditComplete || null,
          }}
          permittingUsers={permittingUsers}
          form={summaryForm}
        />
        <JurisdictionAuditZoningSection
          jurisdictionAuditZoningFields={
            permissions!.jurisdictionAuditZoningFields
          }
          isEditing={isEditing}
          onValuesChange={onValuesChange}
          data={zoning}
          form={zoningForm}
          hasZoning={jurisdictionData?.zoning !== null}
        />
        <JurisdictionAuditPermittingSection
          jurisdictionAuditPermittingFields={
            permissions!.jurisdictionAuditPermittingFields
          }
          isEditing={isEditing}
          onValuesChange={onValuesChange}
          data={permittingDefaultData}
          form={permittingForm}
          hasPermitting={jurisdictionData?.permitting !== null}
        />
      </div>
      {isEditing && (
        <section className="prov-permitting-jurisdiction-audit__actions">
          <PrimaryButton
            title="Submit"
            onClick={onSubmit}
            disabled={buttonsDisabled}
          />
          <PrimaryButton
            title="Cancel"
            disabled={buttonsDisabled}
            type="default"
            onClick={onCancel}
          />
        </section>
      )}
    </main>
  );
};

export const PermittingJurisdictionAudit: FC = () => {
  const permissions = useSelector(UserSelectors.getPermittingBrowsePermissions);

  const { site } = useSelector(
    PermittingSelectors.getPermittingDetailsData(['site']),
  );

  const projectType = useSelector(PermittingSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit(
      'permitting',
      SubPage.PermittingBrowse,
      Tab.JurisdictionAudit,
    ),
  );

  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatusId = useSelector(ProjectSelectors.getProjectStatus);
  const projectStatus = useSelector(
    DDVSelectors.getCurrentDDVValueById<ProjectStatusEnum>(
      DropDownSelectorValues.ProjectStatus,
      projectStatusId,
    ),
  );

  return (
    <EditablePage
      editableComponent={
        site?.jurisdiction ? JurisdictionAudit : NoJurisdiction
      }
      permissions={permissions}
      canUserEdit={
        !!site?.jurisdiction &&
        canEdit &&
        projectTypePermission &&
        canUserEditByProjectStatusAndRoles(projectStatus, userRoles)
      }
      data={site?.jurisdiction}
    />
  );
};
